import html, { htmlAttributes } from "decorator-shared/html";
import type { IconProps } from "./types";

export const HouseIcon = ({ ariaLabel, ...props }: IconProps = {}) => html`
  <svg
    ${htmlAttributes({ ariaHidden: ariaLabel ? "false" : "true", ...props })}
    ${ariaLabel ? html`aria-label="${ariaLabel}"` : ""}
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    focusable="false"
    role="img"
  >
    <path
      fill="currentColor"
      fill-rule="evenodd"
      d="M11.47 2.47a.75.75 0 0 1 1.06 0l7 7c.141.14.22.331.22.53v11a.75.75 0 0 1-.75.75h-5a.75.75 0 0 1-.75-.75v-4.25h-2.5V21a.75.75 0 0 1-.75.75H5a.75.75 0 0 1-.75-.75V10a.75.75 0 0 1 .22-.53zm-5.72 7.84v9.94h3.5V16a.75.75 0 0 1 .75-.75h4a.75.75 0 0 1 .75.75v4.25h3.5v-9.94L12 4.06z"
      clip-rule="evenodd"
    />
  </svg>
`;
