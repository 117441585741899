import html, { htmlAttributes } from "decorator-shared/html";
import type { IconProps } from "./types";

export const InformationSquareIcon = ({
  ariaLabel,
  ...props
}: IconProps = {}) => html`
  <svg
    ${htmlAttributes({ ariaHidden: ariaLabel ? "false" : "true", ...props })}
    ${ariaLabel ? html`aria-label="${ariaLabel}"` : ""}
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    focusable="false"
    role="img"
  >
    <path
      fill="currentColor"
      fill-rule="evenodd"
      d="M4 3.25a.75.75 0 0 0-.75.75v16c0 .414.336.75.75.75h16a.75.75 0 0 0 .75-.75V4a.75.75 0 0 0-.75-.75zm.75 16V4.75h14.5v14.5zM11 7.75a1 1 0 1 1 2 0 1 1 0 0 1-2 0M10.5 10a.75.75 0 0 0 0 1.5h.75v4h-.75a.75.75 0 0 0 0 1.5h3a.75.75 0 0 0 0-1.5h-.75v-4.75A.75.75 0 0 0 12 10z"
      clip-rule="evenodd"
    />
  </svg>
`;
