import html, { htmlAttributes } from "decorator-shared/html";
import type { IconProps } from "./types";

export const ExclamationmarkTriangleIcon = ({
  ariaLabel,
  ...props
}: IconProps = {}) => html`
  <svg
    ${htmlAttributes({ ariaHidden: ariaLabel ? "false" : "true", ...props })}
    ${ariaLabel ? html`aria-label="${ariaLabel}"` : ""}
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    focusable="false"
    role="img"
  >
    <path
      fill="currentColor"
      fill-rule="evenodd"
      d="M12 2.25a.75.75 0 0 1 .656.387l9.527 17.25A.75.75 0 0 1 21.526 21H2.474a.75.75 0 0 1-.657-1.113l9.527-17.25A.75.75 0 0 1 12 2.25m8.255 17.25L12 4.551 3.745 19.5zM12 8.75a.75.75 0 0 1 .75.75v4a.75.75 0 0 1-1.5 0v-4a.75.75 0 0 1 .75-.75m0 6.75a1 1 0 1 0 0 2 1 1 0 0 0 0-2"
      clip-rule="evenodd"
    />
  </svg>
`;
